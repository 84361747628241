import React, { useState, useContext } from "react";
import "./home.css";
import { BottomDrawerComponent } from "../../components/bottomdrawer/bottomDrawer";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import reactIcon from "@iconify/icons-cib/react";
import brandReactNative from "@iconify/icons-tabler/brand-react-native";

import mongodbIcon from "@iconify/icons-cib/mongodb";
import javascript24Filled from "@iconify/icons-fluent/javascript-24-filled";
import nodejsIcon from "@iconify/icons-whh/nodejs";
import html5Filled from "@iconify/icons-ant-design/html5-filled";
import css3Icon from "@iconify/icons-cib/css3";
import reduxIcon from "@iconify/icons-cib/redux";
import materialUi from "@iconify/icons-mdi/material-ui";
import firebaseIcon from "@iconify/icons-mdi/firebase";
import materialDesign from "@iconify/icons-cib/material-design";
import arrowMove from "@iconify/icons-si-glyph/arrow-move";
import handWave from "@iconify/icons-mdi/hand-wave";
import chatIcon from "@iconify/icons-cryptocurrency/chat";
import { themeContext } from "../../context/themeContext";

export const HomePage = () => {
  const [matchTab, setMatchTab] = useState("/Web");
  const [homeDrawer, setHomeDrawer] = useState(false);
  const toggleDrawer = () => setHomeDrawer(!homeDrawer);

  const handleChange = (e) => {
    setMatchTab(e.target.value);
  };
  const { theme, dark, light } = useContext(themeContext);

  const Stacks = {
    mobile: [
      {
        title: "React Native",
        id: "m.1",
        icon: (
          <Icon
            icon={brandReactNative}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
      },
      {
        title: "React Navigation",
        id: "m.2",
        icon: (
          <Icon
            icon={arrowMove}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
      },

      {
        title: "Firebase",
        id: "m.4",
        icon: (
          <Icon
            icon={firebaseIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
      },
      {
        title: "Redux",
        icon: (
          <Icon
            icon={reduxIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "m.5",
      },
      {
        title: "RN Paper",
        id: "m.3",
        icon: (
          <Icon
            icon={materialDesign}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
      },
    ],

    web: [
      {
        title: "Javascript",
        icon: (
          <Icon
            icon={javascript24Filled}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w1",
      },
      {
        title: "Node.js",
        icon: (
          <Icon
            icon={nodejsIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w2",
      },

      {
        title: "HTML 5",
        icon: (
          <Icon
            icon={html5Filled}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 41,
              height: 41,
            }}
          />
        ),
        id: "w4",
      },
      {
        title: "CSS3",
        icon: (
          <Icon
            icon={css3Icon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w5",
      },

      {
        title: "Firebase",
        id: "m.4",
        icon: (
          <Icon
            icon={firebaseIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
      },
      {
        title: "MongoDB",
        icon: (
          <Icon
            icon={mongodbIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w7",
      },
      {
        title: "Reactjs",
        icon: (
          <Icon
            icon={reactIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w3",
      },
      {
        title: "Redux",
        icon: (
          <Icon
            icon={reduxIcon}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w6",
      },
      {
        title: "Material-Ui",
        icon: (
          <Icon
            icon={materialUi}
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              width: 31,
              height: 41,
            }}
          />
        ),
        id: "w8",
      },
    ],
  };

  const NavItem = ({ path }) => {
    return path === "/Web"
      ? Stacks.web.map((item) => (
          <li key={item.id} className="Tech-stack-list-item">
            <span className="Tech-stack-list-icon">{item.icon}</span>
            <span
              className="Tech-stack-list-text"
              style={{ color: theme === "dark" ? dark.text : light.text }}
            >
              {item.title}
            </span>
          </li>
        ))
      : Stacks.mobile.map((item) => (
          <li key={item.id} className="Tech-stack-list-item">
            <span className="Tech-stack-list-icon">{item.icon}</span>
            <span
              className="Tech-stack-list-text"
              style={{ color: theme === "dark" ? dark.text : light.text }}
            >
              {item.title}
            </span>
          </li>
        ));
  };

  return (
    <div
      className="Home"
      style={{
        minHeight: "calc(100vh - 120px)",
        display: "flex",
        flexDirection: "column",
        background: theme === "dark" ? dark.background : light.background,
      }}
    >
      <div className="Home-container">
        <div className="Home-contents">
          {/* About Me */}
          <div
            className="Whoami"
            style={{ color: theme === "dark" ? dark.text : light.text }}
          >
            <h4>
              Hi{" "}
              <Icon
                icon={handWave}
                color={theme === "dark" ? dark.primary : light.primary}
                hFlip={true}
              />
              , My Name Is
            </h4>
            <h4
              style={{
                textDecoration: "none",
                fontWeight: "800",
                fontSize: 27,
                color: theme === "dark" ? dark.primary : light.primary,
              }}
            >
              Joshua Agbo
            </h4>
            <h4>Web/Mobile Software Developer</h4>
            <Link
              to="/about"
              className={`Cv ${theme === "dark" && "Dark-active"} ${
                theme === "light" && "Light-active"
              }`}
            >
              &rarr; Continue reading...
            </Link>
          </div>

          <div className="Tech-stacks">
            <h4
              className="Title"
              style={{
                color: theme === "dark" ? dark.text : light.text,
              }}
            >
              Technology Stacks
            </h4>

            <div className="Tech-stack-header">
              <input
                type="radio"
                id="web"
                name="stacks"
                value="/Web"
                style={{ display: "none" }}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="web" className="Tech-stack-label">
                <span
                  className={`${
                    matchTab !== "/Web" && theme === "dark" ? "Dark" : null
                  } ${
                    matchTab === "/Web" && theme === "dark"
                      ? "Dark-active"
                      : null
                  } ${
                    matchTab === "/Web" && theme === "light"
                      ? "Light-active"
                      : null
                  }`}
                >
                  &rarr; Web
                </span>
              </label>
              <input
                type="radio"
                id="mobile"
                value="/Mobile"
                name="stacks"
                style={{ display: "none" }}
                onChange={(e) => handleChange(e)}
              />
              <label
                htmlFor="mobile"
                style={{ marginLeft: "16px" }}
                className="Tech-stack-label"
              >
                <span
                  className={`${
                    matchTab !== "/Moblle" && theme === "dark" ? "Dark" : null
                  } ${
                    matchTab === "/Mobile" && theme === "dark"
                      ? "Dark-active"
                      : null
                  } ${
                    matchTab === "/Mobile" && theme === "light"
                      ? "Light-active"
                      : null
                  }`}
                >
                  &rarr; Mobile
                </span>
              </label>
            </div>
            <ul className="Tech-stack-list">
              <NavItem path={matchTab} />
            </ul>
          </div>
        </div>

        <button
          className="Home-action"
          onClick={() => toggleDrawer()}
          style={{
            padding: "3px",
            background: theme === "dark" ? dark.background : "#e5e5e5",
            border:
              theme === "dark"
                ? `2px solid ${dark.primary}`
                : `2px solid ${light.primary}`,
          }}
        >
          <Icon
            icon={chatIcon}
            style={{
              width: "100%",
              height: "100%",
              color: theme === "dark" ? dark.primary : light.primary,
            }}
          />
        </button>
        <BottomDrawerComponent
          isDrawerVisibility={homeDrawer}
          toggleDrawer={toggleDrawer}
        />
      </div>

      <div
        className="Footer"
        style={{
          borderTop: theme === "dark" ? `1px solid #222` : "1px solid #ccc",
        }}
      >
        <span
          className="Footer-text"
          style={{
            color: theme === "dark" ? dark.text : light.text,
            fontSize: 12,
          }}
        >
          &copy;{" "}
          <span
            className="Footer-title"
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Gems
          </span>{" "}
          2023
        </span>
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { themeContext } from "../../context/themeContext";
import "./about.css";
import { Icon } from "@iconify/react";
import emailFill from "@iconify/icons-eva/email-fill";
import whatsappIcon from "@iconify/icons-dashicons/whatsapp";
import profile2 from "../../static/profile2.jpg";
import resume from "../../static/Resume.pdf";
const whatsappLink = "https://api.whatsapp.com/message/JCLPY4M4ZKJ4F1";
const mailTo = "mailto:developer.gemjoshua@gmail.com";

export const AboutPage = () => {
  const { theme, dark, light } = useContext(themeContext);

  return (
    <div
      className="About"
      style={{
        background: theme === "dark" ? dark.background : light.background,
        minHeight: "calc(100vh - 120px)",
      }}
    >
      <div className="About-container">
        <div
          className="About-profile"
          style={{ color: theme === "dark" ? dark.text : light.text }}
        >
          <div className="About-header">
            <div className="About-header-image">
              <img
                src={profile2}
                alt="jems avatar"
                className="profileImage"
                style={{
                  border: theme === "dark" ? `5px solid ${dark.primary}` : null,
                }}
              />
            </div>
            <div className="About-header-text">
              <h2
                className="About-title-name"
                style={{
                  color: theme === "dark" ? dark.primary : light.primary,
                }}
              >
                Joshua Agbo
              </h2>
              <span className="About-title-description">
                Web/Mobile Application Developer
              </span>
              <a
                href={resume}
                className="About-resume"
                style={{
                  color: theme === "dark" ? dark.primary : light.primary,
                }}
                download
              >
                See Resume
              </a>
            </div>
          </div>
          <div className="About-body">
            <h2
              className="About-body-title"
              style={{ color: theme === "dark" ? dark.text : light.text }}
            >
              More About Me
            </h2>
            <h4>
              Full Name Joshua Agbo, an enthusiast self-taught fullstack
              web/mobile application developer.
            </h4>
            <h4>
              With over 5yrs learning and building projects for self and
              Clients. Currently, am comfortable with HTML, CSS3, Javascript,
              ReactNative, RestAPIs, React.js, Node.js, Next.js, Non-RDBMs like
              mongodb, Firebase. Am interested on modern web development with
              Javascript, mobile development with Kotlin and React-native, and
              ML with openCv. Aside freelance and personal projects, I will love
              to work for/with other developers/team to solve companies
              technological challenges.
            </h4>
            <h4 style={{ marginTop: 16 }}>
              Use my quick connection link below to reach me immediately
            </h4>
          </div>
        </div>

        <div className="Quick-link">
          <h2
            className="Quick-link-title"
            style={{ color: theme === "dark" ? dark.text : light.text }}
          >
            Quick Connection &rarr;
          </h2>
          <div className="aboutConnectList">
            <a
              href={mailTo}
              className="aboutContactLink"
              style={{ color: theme === "dark" ? dark.primary : light.text }}
            >
              <Icon icon={emailFill} style={{ width: 36, height: 36 }} />
            </a>
            <a
              href={whatsappLink}
              className="aboutContactLink"
              style={{ color: theme === "dark" ? dark.primary : light.text }}
            >
              <Icon icon={whatsappIcon} style={{ width: 36, height: 36 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import "./bottomDrawer.css";
import { themeContext } from "../../context/themeContext";
import { Icon } from "@iconify/react";
import emailFill from "@iconify/icons-eva/email-fill";
import whatsappIcon from "@iconify/icons-dashicons/whatsapp";
import closeIcon from "@iconify/icons-carbon/close";

export const BottomDrawerComponent = ({ isDrawerVisibility, toggleDrawer }) => {
  const whatsappLink = "https://api.whatsapp.com/qr/AEVH624XBVQEL1";
  const mailTo = "mailto:developer.gemjoshua@gmail.com";
  const { theme, dark, light } = useContext(themeContext);

  return (
    <div
      className="bottomDrawer"
      style={{ visibility: isDrawerVisibility ? "visible" : "hidden" }}
    >
      <div
        className="bottomDrawerOverlay"
        onClick={toggleDrawer}
        onDragLeave={toggleDrawer}
        style={{
          background: theme === "dark" ? dark.bgAppbar : light.bgAppbar,
        }}
        draggable
      />
      <button
        className="btnClose"
        style={{
          color: light.primary,
          background: theme === "dark" ? dark.background : light.background,
        }}
        onClick={toggleDrawer}
      >
        <Icon icon={closeIcon} style={{ fontSize: "45px" }} />
        <span>close</span>
      </button>
      <div
        className="bottomDrawerContainer"
        onDragLeave={toggleDrawer}
        draggable
        style={{
          background: theme === "dark" ? dark.background : "#e5e5e5",
        }}
      >
        <h4
          className="bottomDrawerTitle"
          style={{
            color: theme === "dark" ? dark.text : light.text,
          }}
        >
          Connect with{" "}
          <span
            className="bottomDrawerTitleDiff"
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
            }}
          >
            Joshua Agbo
          </span>
        </h4>
        <div className="bottomDrawerActions">
          <a
            href={mailTo}
            className="bottomDrawerIcon"
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
            }}
          >
            <Icon
              icon={emailFill}
              style={{ width: 48, height: 53, fontSize: 35 }}
            />
            <span className="Bottom-drawer-icon-text">E-mail</span>
          </a>
          <a
            href={whatsappLink}
            className="bottomDrawerIcon"
            target="self"
            style={{
              color: theme === "dark" ? dark.primary : light.primary,
            }}
          >
            <Icon
              icon={whatsappIcon}
              style={{ width: 48, height: 53, fontSize: 35 }}
            />
            <span className="Bottom-drawer-icon-text">WhatsApp</span>
          </a>
        </div>
      </div>
    </div>
  );
};

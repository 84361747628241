import React, { createContext } from "react";

export const themeContext = createContext();

/*
============
// Theme
============
*/
const darkTheme = {
  background: "#1F1B24",
  bgAppbar: "#121212",
  text: "#ddd",
  primary: "#efb086",
};
const defaultTheme = {
  background: "#ddd",
  bgAppbar: "#E5E5E5",
  text: "#3b3b3b",
  primary: "#F4678E",
};

const ContextProvider = (props) => {
  const [theme, setTheme] = React.useState("light");

  return (
    <themeContext.Provider
      {...props}
      value={{ theme, setTheme, dark: darkTheme, light: defaultTheme }}
    />
  );
};

export default ContextProvider;

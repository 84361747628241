import React, { useContext } from "react";
import { Link } from "react-router-dom";
import notfound from "./notfound.gif";
import "./e404.css";
import { Icon } from "@iconify/react";
import { themeContext } from "../../context/themeContext";
import backhandIndexPointingLeftMediumSkinTone from "@iconify/icons-emojione/backhand-index-pointing-left-medium-skin-tone";

export const E404 = () => {
  const { theme, dark, light } = useContext(themeContext);

  return (
    <div
      className="e404"
      style={{
        background: theme === "dark" ? dark.background : light.background,
      }}
    >
      <img src={notfound} className="e404Image" alt="Error 404 gif" />

      <h4
        className="e404Text"
        style={{ color: theme === "dark" ? dark.text : light.text }}
      >
        Page Not Found
      </h4>
      <Link
        to="/"
        className={`E-home-link ${theme === "dark" && "Dark"} ${
          theme === "dark" && "Dark-active"
        } ${theme === "light" && "Light-active"}`}
      >
        Return Home
        <Icon
          icon={backhandIndexPointingLeftMediumSkinTone}
          style={{ width: 23, height: 23, color: "#ff9671", marginLeft: 10 }}
        />
      </Link>
    </div>
  );
};

import React from "react";
import "./App.css";
import ThemeProvider from "./context/themeContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppBar } from "./components/appbar/appbar";
import { HomePage } from "./pages/home/homePage";
import { AboutPage } from "./pages/about/about";
import { E404 } from "./components/404/e404";

const App = () => {
  return (
    <ThemeProvider>
      <Router>
        <AppBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="*" element={<E404 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;

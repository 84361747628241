import React, { useContext } from "react";
import "./appbar.css";
import { Icon } from "@iconify/react";
import { themeContext } from "../../context/themeContext";
import { NavLink } from "react-router-dom";
import githubFill from "@iconify/icons-akar-icons/github-fill";
import linkedinIcon from "@iconify/icons-brandico/linkedin";
import twitterFill from "@iconify/icons-akar-icons/twitter-fill";
import lightIcon from "@iconify/icons-carbon/light";
import bxsMoon from "@iconify/icons-bx/bxs-moon";

export const AppBar = () => {
  const { theme, setTheme, dark, light } = useContext(themeContext);

  const githubAcc = "https://github.com/josh-ag";
  const twitterAcc = "https://twitter.com/_joshag_";
  const linkedIn = "https://linkedin.com/in/gemjoshua";

  return (
    <div
      className="Appbar"
      style={{
        background: theme === "dark" ? dark.background : light.bgAppbar,
        borderBottom: theme === "dark" ? `2px solid #222` : 0,
      }}
    >
      <div className="Appbar-container">
        <div className="Left">
          <NavLink
            to="/"
            className="Appbar-title"
            style={{ color: theme === "dark" ? dark.primary : light.primary }}
          >
            GEMS
          </NavLink>

          <nav className="Appbar-socials">
            <a
              href={githubAcc}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme === "dark" ? dark.primary : "#3b5998" }}
            >
              <Icon icon={githubFill} />
            </a>
            <a
              href={linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme === "dark" ? dark.primary : "#3b5998" }}
            >
              <Icon icon={linkedinIcon} />
            </a>
            <a
              href={twitterAcc}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme === "dark" ? dark.primary : "#3b5998" }}
            >
              <Icon icon={twitterFill} />
            </a>
          </nav>
        </div>
        <div className="Right">
          {theme === "dark" && (
            <button onClick={() => setTheme("light")}>
              <Icon icon={lightIcon} color="#ff9a62" />
            </button>
          )}

          {theme === "light" && (
            <button onClick={() => setTheme("dark")}>
              <Icon icon={bxsMoon} color={light.text} rotate={3} vFlip={true} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
